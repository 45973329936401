$section-offset: 20px;
$section-main-padding-horizontal-fullscreen: 10px;

// device-width
$width-large-screen: 1920px;
$width-medium-screen: 1440px;
$width-small-screen: 1280px;

// heading
$offset-heading-small: 10px;
$offset-heading-small-x: 12px;
$offset-heading-medium: 20px;
$offset-heading-large: 20px;
