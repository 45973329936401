.blueprint-input {
    &__image-input-wrapper {
        position: relative;
    }

    &__path-input-wrapper {
        position: relative;
    }

    &__refresh {
        position: absolute;
        top: 2px;
        right: 2px;
    }

    &__loader {
        position: absolute;
        left: 0;
        right: 0;
        margin-inline: auto;
        width: fit-content;
        opacity: 0.7;
        height: 100%;
    }
}
